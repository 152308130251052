import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';

const validationSchema = yup.object({
  name: yup
    .string('Please enter your name')
    .required('Please enter your name'),
  email: yup
    .string('Please enter your email')
    .email('Please enter a valid email')
    .required('Please enter your email'),
})

function RequestInvite() {

    const [submitted, setSubmitted] = useState(false);

    const formik = useFormik({
      
        initialValues: {
            name: '',
            email: '',
        },
        
        validationSchema: validationSchema,
        onSubmit: (values) => {

            setSubmitted(true);
            let { email, name } = values;
            let data = { name, email };
            const params = {
                'method': 'POST',
                'contentType': 'application/json',
                'body': JSON.stringify(data)
            };
            const url = 'https://script.google.com/macros/s/AKfycby0SiLD8vQeoi2VIloUeq5jQ5YQSQZtkOCsK7uY_67MdxJq9umlxLA7ajmsFlTZug8r/exec'

            fetch(url, params);
                // .then(
                //     function (response) {
                //         if (response.status === 200) {
                //             console.log('Success');
                //         } else {
                //             console.log('Problem. Status code:' + response.status);
                //         };
                //     })
                // .catch(function (err) {
                //     console.log('Error:', err);
                // });
        }
    })

    if (submitted === false) {
        return (
            <>

                <Box component='form' onSubmit={formik.handleSubmit} noValidate borderRadius='10px 10px 10px 10px' sx={{
                    backgroundColor: 'lightgrey',
                    opacity: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                }}>
          
                    <Typography fontWeight='bold'>Sign me up for a beta invite when it's ready!</Typography>
                    <TextField name='name' id='name' required sx={{ mt: 1 }} align='top' label='(your name)'
                        value={formik.values.name} onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField name='email' id='email' required sx={{ mt: 1 }} label='(your email)'
                        value={formik.values.email} onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <Button type='submit' variant='contained'  >OK, we will!</Button>
                </Box>
            </>
        )
    } else {
        return (
            <>

                <Box component='form' onSubmit={formik.handleSubmit} noValidate borderRadius='10px 10px 10px 10px' sx={{
                    backgroundColor: 'lightgrey',
                    opacity: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                }}>
          
                    <Typography fontWeight='bold'>You're all set. Thank you for your interest!</Typography>
                </Box>
            </>
        )
    };
    
}

export default RequestInvite;
