import Background from './background.jpg'
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import RequestInvite from './RequestInvite';

const App = () => {
  return (
    <>
      <CssBaseline />
      {/* Set up box around all other data with background image*/}
      <Box
        position='relative'
        sx={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', // fills size of box
        minHeight: '100vh' // size of box = greater of visual screen or content
      }}>

      {/* Add toolbar/log and set elvation to 0 */}
        <AppBar position='fixed' color='transparent' elevation={0} >
        <Toolbar>
          <Typography color="white"
            sx={{
              //flexGrow: 1, //note: would fill up whole space, not just text
              fontSize: { xs: 'h5.fontSize', sm: 'h3.fontSize' },
              letterSpacing: 4, fontWeight: 'bold',
              backgroundColor: 'black',
              px: '3px' //padding on left and right of logo
            }}>
            Lexfile
          </Typography>
        </Toolbar>
      </AppBar>
        {/* Add Grid for main content of site */}
        <Grid container>
          <Grid item xs={12} sx={{ minHeight: '20vh' }} />
          <Grid item xs={1} sm={2} />
          <Grid item xs={10} sm={8}
            sx={{
            margin: 'center'
            }}>
            {/* make font size and weight smaller if smaller screen */}
            <Typography sx={{
              fontSize: { xs: 20, sm:45, md:55},
              fontWeight: { xs: 550, sm: 575, md:600 },
              color: 'white',
            }}>A fantastic platform to grow your practice</Typography>
            <Typography sx={{
              fontSize: { xs: 16, sm: 24, md: 27 },
              fontWeight: { xs: 300, sm: 325, md:350 },
              color: 'white',
            }}>Coming soon: payments, scheduling, agreements, communications, and client files</Typography>
            </Grid>
            <Grid item xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
              <Box width='100px' height='8px' backgroundColor='gold'></Box>
          </Grid>
          <Grid item xs={12}><Box height='300px'></Box></Grid>
        </Grid>

        {/* Add box to request invite when beta version is ready at the bottom of the screen */}
        <Grid container sx={{
          position: 'absolute',
          bottom: '5%',
        }}>
          <Grid item xs={1} sm={2} md={3} lg={4}/>
          <Grid item xs={10} sm={8} md={6} lg={4}>
            <RequestInvite />
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={4}/>
         </Grid>
  
      </Box>
    </>
  );
}

export default App;